import { Show, Suspense, createEffect, onMount } from "solid-js";
import { useNavigate, createAsync, useSubmission } from "@solidjs/router";
import { logoutAction } from "~/services/session";
import { getAccountStatus } from "~/services/account";
import { useSessionContext } from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import { arrowRightOnRectangle, userCircle } from "solid-heroicons/outline";
import { user } from "solid-heroicons/outline";
import { BaseLoader, Menu, type MenuGroup } from "../utility";
import {
  accountNav,
  shoppingNav,
  adminNav,
  filterByPermissions,
} from "~/utils/account";
import { getPermissionsCacheFn } from "~/services/permissions/getPermissionsCacheFn";

export const ProfileQuickLinks = () => {
  const { clearSession, isPartner, session } = useSessionContext();
  const loggingOut = useSubmission(logoutAction);

  const navItems = [
    { label: "Admin", list: adminNav },
    { label: "Account", list: accountNav },
    { label: "Shopping", list: shoppingNav },
    {
      list: [
        {
          label: "Log Out",
          icon: arrowRightOnRectangle,
          permission: null,
          onSelect: async () => {
            await clearSession();
            // navigate("/");
          },
        },
      ],
    },
  ];

  const permissions = createAsync(
    async () => {
      if (isPartner()) {
        const data = await getPermissionsCacheFn();
        const arr = Array.from(data);
        return arr;
      }
    },
    { deferStream: true }
  );

  const filteredMenuOptions = () =>
    navItems.map((item) => ({
      label: item.label,
      list: filterByPermissions(item.list, permissions() ?? []),
    }));

  // TODO - render an error version when this fails to get permissions?

  return (
    <Suspense>
      <Menu
        header={
          <>
            <div class="pl-6  text-sm font-medium text-black class flex items-center">
              <Icon path={userCircle} class="w-5 h-5 mr-2 shrink-0" />
              <span class="truncate max-w-[170px] grow">
                {session()?.name ? `Hi, ${session()?.name}!` : "Account Menu"}
              </span>
              <button
                title="Logout"
                type="button"
                onClick={async () => {
                  await clearSession();
                }}
                class="mr-1 shrink-0 text-roma-medium-grey active:translate-y-0.5"
              >
                <Icon path={arrowRightOnRectangle} class="w-5   h-5 " />
              </button>
            </div>
          </>
        }
        aria-label="My Account"
        list={filteredMenuOptions() as MenuGroup[]}
      >
        <div class="flex items-center hover:text-roma-blue">
          <Show
            when={loggingOut.pending}
            fallback={
              <Icon path={user} class="w-5 h-5 mr-1" stroke-width="1.75" />
            }
          >
            <BaseLoader width={5} height={5} class="!mr-1" />
          </Show>
          <span>My Account</span>
        </div>
      </Menu>
    </Suspense>
  );
};
